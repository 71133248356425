<template>
  <base-section
    id="privacypolicy"
  >
    <v-responsive
      class="mx-auto"
      max-width="1350"
    >
      <v-container fluid>
        <p>
          This Privacy Policy (“Privacy Policy”) describes and governs the way Stout Funding collects, uses, maintains and discloses
          information collected from individuals (each, a “User”) who use our https://www.StoutFunding.com site and our related
          webpages (each, a “Site”). This Privacy Policy applies to the Sites and all products and services offered by Stout Funding.
        </p>
        <h4>PERSONAL IDENTIFICATION INFORMATION THAT YOU GIVE TO US</h4>
        <p>
          We may collect personal identification information that Users chose to provide to us, including, but not limited to, Users
          information needed to register for membership or mailing list, subscribe to news and updates, respond to a survey, fill out
          a form, and in connection with other activities, services, features or resources we make available on our Site.
        </p>
        <p>
          We will collect personal identification information from Users only if they voluntarily submit such information to us. Users
          can always use the Site anonymously or refuse to supply personal identification information.
        </p>
        <h4>NON-PERSONAL IDENTIFICATION INFORMATION</h4>
        <p>
          We may collect non-personal identification information about Users whenever they interact with our Sites. Such non-
          personal identification information may include web browser name, the type of computer and device information, such as
          Internet Protocol (“IP”) addresses, log information, error messages, device type, and unique device identifiers.
        </p>
        <h4>WEB BROWSER COOKIES</h4>

        <p>
          Our Sites may use “cookies” to enhance User experience. User’s web browser places cookies on their hard drive to
          collect information about Users so we can provide the experiences User’s request, recognize User’s visit, track
          interactions, and improve User’s experience. Users have control over some of the information we collect from cookies and
          how cookies are used. If they do so, note that some parts of the Site may not function properly.
        </p>
        <h4>HOW WE USE COLLECTED INFORMATION</h4>
        <p>
          Stout Funding may collect and use Users personal information for the following purposes:
          <ul>
            <li>To improve customer service.</li>
            <li>To personalize user experience.</li>
            <li>To improve our Sites.</li>
            <li>To run a promotion, contest, survey or other Site feature.</li>
            <li>To send periodic emails.</li>
            <li>As required by law or valid legal process, or to protect the personal safety of our members or the public.</li>
          </ul>
        </p>
        <h4>HOW WE PROTECT YOUR INFORMATION</h4>
        <p>
          We adopt policies and measures to protect unauthorized access, alteration, disclosure or destruction of User personal
          information stored on our Sites.
        </p>
        <h4>SHARING YOUR PERSONAL INFORMATION</h4>
        <p>
          We do not sell, trade, or rent Users personal identification information. We may share generic aggregated de-identified
          information with our business partners, trusted affiliates and advertisers. Such information is not linked to any personal
          identification information regarding any Users. We may use third party service providers to help us operate our business
          and the Sites or administer activities on our behalf, such as sending out newsletters or surveys. We may share your
          information with third parties for those limited purposes and only to the extent necessary, provided that you have not
          opted-out.
        </p>
        <h4>THIRD PARTY WEBSITES</h4>
        <p>
          Users may find advertising or other content on our Sites that link to the sites and services of third parties. We do not
          control the content or links that appear on these sites and are not responsible for the practices employed by websites linked
          to or from our Sites and this Privacy Policy does not apply to such websites. These sites and services may have
          their own privacy policies and customer service policies.
        </p>
        <h4>CHANGES TO THIS PRIVACY POLICY</h4>
        <p>
          Stout Funding has the discretion to update this privacy policy from time to time and at any time without notice. When we do,
          we will revise the updated date at the bottom of this page. You acknowledge and agree that it is your responsibility to
          review this privacy policy periodically and become aware of modifications. We encourage Users to frequently check this
          page for any future changes.
        </p>
        <h4>YOUR ACCEPTANCE OF THESE TERMS</h4>
        <p>
          By using the Sites, you signify your acceptance of this policy. If you do not agree to this policy, please do not use our
          Sites. Your continued use of the Sites following the posting of changes to this policy will be deemed your acceptance of
          those changes.
        </p>
        <h4>CONTACTING US</h4>
        <p>
          If you have any questions about this Privacy Policy, the practices of the Sites, or your dealings with the Sites, please
          contact us at:
        </p>
        <p>
          Web: http://www.StoutFunding.com <br />
          Address<br />
          3847 S. Blvd, Suite 200<br />
          Edmond, OK 73013
        </p>
      </v-container>
    </v-responsive>
  </base-section>
</template>

<script>
  // import * as api from '@/functions/api.js'

  export default {
    name: 'SectionFeatures',

    data: () => ({
    }),
    mounted () {
      this.initialize()
    },
    methods: {
      initialize () {
      },
    },
  }
</script>
